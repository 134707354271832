import { CSSProperties } from "react";

import "@mui/lab/themeAugmentation";
import { colors } from "@mui/material";
import { green, grey, orange, teal } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// https://www.figma.com/file/sY2JlDgKlr8E9pxKLWR8Y4/Manage-Account?node-id=131%3A865
// For colour swatches, see: https://next.material-ui.com/customization/color/#color-palette
export const BHPalette = {
  primary: orange[800],
  secondary: teal[700],

  grey: {
    header: "#FEFEFE",
    subheader: grey[50],
    separator: grey[200]
  },

  accents: {
    secondaryActive: teal[50]
  },

  body: {
    BHW: grey[100]
  },

  custom: {
    byline: "#5c5c5c"
  },

  text: {
    dark: {
      primary: "rgba(0, 0, 0, .87)",
      secondary: "rgba(0, 0, 0, .54)",
      disabled: "rgba(0, 0, 0, .38)"
    },
    light: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(255, 255, 255, .7)",
      disabled: "rgba(255, 255, 255, .5)"
    }
  }
};

export enum FontWeight {
  /** Equivalent of FontWeight 400 */
  regular = 400,
  /** Equivalent of FontWeight 500 */
  medium = 500
}

// Custom breakpoints for main BHW web app.
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    baseLayoutXL: true; // adds the `baseLayoutXL` breakpoint
    baseLayoutL: true;
    baseLayoutMD: true;
    baseLayoutSM: true;
  }
}

const defaultTheme = createTheme();

// Apply the theme to the default Material UI theme
export const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          ".MuiPaper-root": { borderRadius: "20px" },
          ".MuiDialogTitle-root": { paddingTop: 24 },
          ".MuiDialogContent-root": { paddingBottom: 24 }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none"
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          height: 60,
          minHeight: 60
        }
      }
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          ".MuiPickersDay-root.Mui-disabled": {
            color: colors.grey[300]
          },
          // Targets the day of week buttons (most specific class found)
          ".MuiTypography-root": {
            color: colors.grey[900]
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      baseLayoutXL: 1920,
      baseLayoutL: 1440,
      baseLayoutMD: 1366,
      baseLayoutSM: 1024
    }
  },
  palette: {
    text: {
      ...BHPalette.text.dark
    },
    primary: {
      main: BHPalette.primary,
      light: orange[50]
    },
    secondary: {
      main: BHPalette.secondary,
      50: BHPalette.accents.secondaryActive,
      dark: teal[900]
    },
    header: {
      main: BHPalette.grey.header,
      sub: BHPalette.grey.subheader,
      byline: BHPalette.custom.byline
    },
    background: {
      default: BHPalette.body.BHW
    },
    separator: BHPalette.grey.separator,
    accent: {
      iconBackground: green[800],
      text: "#1E1E1E",
      containerBackground: green[800]
    }
  },
  typography: {
    headline: {
      fontSize: "24px",
      fontWeight: FontWeight.regular
    },
    title1: {
      fontSize: "20px",
      fontWeight: FontWeight.medium
    },
    title2: {
      fontSize: "20px",
      fontWeight: FontWeight.regular
    },
    body1: {
      fontSize: "14px",
      fontWeight: FontWeight.regular
    },
    body2: {
      fontSize: "13px",
      fontWeight: FontWeight.medium
    },
    body3: {
      fontSize: "16px",
      fontWeight: FontWeight.regular
    },
    body4: {
      fontSize: "14px",
      fontWeight: FontWeight.medium
    },
    button: {
      fontSize: "14px",
      fontWeight: FontWeight.medium
    }
  }
});

// Augment the declared theme with the added typography variants
declare module "@mui/material/styles" {
  interface PaletteOptions {
    header: {
      main: string;
      sub: string;
      byline: string;
    };
    separator: string;
    accent: {
      iconBackground: string;
      containerBackground: string;
      text: string;
    };
  }

  interface Palette {
    header: PaletteOptions["header"];
    separator: string;
    accent: PaletteOptions["accent"];
  }

  interface TypographyVariants {
    headline: CSSProperties;
    title1: CSSProperties;
    title2: CSSProperties;
    body3: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headline?: CSSProperties;
    title1?: CSSProperties;
    title2?: CSSProperties;
    body3?: CSSProperties;
    body4?: CSSProperties;
  }
}
