import { getCountry } from "pages/common/utils";
import * as yup from "yup";

import { Country } from "./booking";

export interface Address {
  street1: string;
  city: string;
  postCode: string;
  suburb?: string;
  addressState?: string;
  country: string;
}

/**
 * Creates a basic schema for validating an address. Schemas are immutable so consumers can modify the returned
 * schema without changing the original.
 *
 * @see https://github.com/jquense/yup/blob/master/docs/extending.md#extending-schema
 */
export const createAddressValidationSchema = (isRequired: boolean) =>
  yup.object().shape({
    street1: yup
      .string()
      .max(40, "The street address must be 40 characters or less")
      .matches(
        /^[\w/\-,'.\s]+$/,
        "The street address contains invalid characters"
      )
      .test({
        name: "address-street-required",
        test: value => !!value || !isRequired,
        message: "Please enter a valid street address"
      }),
    city: yup.string().when("country", {
      is: Country.NewZealand,
      then: yup
        .string()
        .max(40, "The suburb name must be 40 characters or less")
        .matches(
          /^[a-z'\-\s]+$/i,
          "The suburb name contains invalid characters"
        )
        .test({
          name: "address-city-required",
          test: value => !!value || !isRequired,
          message: "Please enter a valid city"
        })
    }),
    addressState: yup.string().when("country", {
      is: Country.Australia,
      then: yup.string().test({
        name: "address-state-required",
        test: value => !!value || !isRequired,
        message: "Please enter a valid state"
      })
    }),
    suburb: yup.string().when("country", {
      is: Country.Australia,
      then: yup.string().test({
        name: "address-suburb-required",
        test: value => !!value || !isRequired,
        message: "Please enter a valid suburb"
      })
    }),
    postCode: yup
      .string()
      .matches(/\d{4}/, "The postcode must be four digits")
      .typeError("Please specify a valid postcode")
      .test({
        name: "address-postcode-required",
        test: value => !!value || !isRequired,
        message: "Please enter a valid postcode"
      })
  });

/**
 * An address match found by google Autocomplete
 */
export interface AddressMatch {
  id: string;
  description: string;
}

export class AddressRestrictions {
  // Minimum number of characters entered before an autocomplete search is done
  public static MinFullTextSearchLength: number = 3;

  // Minimum number of characters entered before manual address entry displays even when there are matches
  public static MinSearchLengthForManualEntry: number = 15;
}

export const LocalCountry = getCountry() || Country.NewZealand;
export const getDefaultAddress = (country?: string): Address => {
  return {
    street1: "",
    city: "",
    postCode: "",
    suburb: "",
    addressState: "",
    country: country ?? LocalCountry
  };
};
